import { WhitelistedPhoneForm } from '@m/types/api';
import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

export const getWhitelistedPhones = createEffect({
  handler: ({ subscriptionPlanId }: { subscriptionPlanId: number }) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}/whitelisted-phones`),
});

export const addWhitelistedPhones = createEffect({
  handler: ({
    subscriptionPlanId,
    whitelistedPhoneForm,
  }: {
    subscriptionPlanId: number;
    whitelistedPhoneForm: WhitelistedPhoneForm;
  }) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}/whitelisted-phones`, {
      method: 'POST',
      body: whitelistedPhoneForm,
    }),
});

export const deleteWhitelistedPhoneV2 = createEffect({
  handler: ({
    subscriptionPlanId,
    whitelistedPhoneNumber,
  }: {
    subscriptionPlanId: number;
    whitelistedPhoneNumber: string;
  }) =>
    apiFetch(
      `/v2/admin/subscription/plan/${subscriptionPlanId}/whitelisted-phone/${whitelistedPhoneNumber}`,
      {
        method: 'DELETE',
      },
    ),
});
