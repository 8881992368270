import { useQuery } from '@tanstack/react-query';
import { normalizeIamRole } from 'apps/admin/services/iam/IamUserService';
import { apiFetch } from 'utils/http';

import { IamRole, RoleType } from 'types/api/iam/Iam';

type Operator = {
  uuid: string;
  name: string;
  id: number;
};
const useDefaultAndSelectOperatorsQuery = (uuid: string) =>
  useQuery({
    queryKey: ['operatorUUID', uuid],
    queryFn: async () => {
      const { data = [] } = await apiFetch<Operator[]>('/v2/operator', {
        statusCodes: true,
      });

      const selectedOperator = data.find((operator) => operator?.uuid === uuid);
      const defaultOperator = data.find((operator) => operator?.name === 'Metropolis');
      return { selectedOperator, defaultOperator };
    },
  });

export default useDefaultAndSelectOperatorsQuery;

export const useUsersAssignedOperatorsQuery = (isManagerApp: boolean = false) =>
  useQuery({
    queryKey: ['getUsersAssignedOperators'],
    queryFn: async () => {
      const { data } = await apiFetch<{
        roles: IamRole[];
      }>('/v2/user/iam/realm/user/roles', {
        statusCodes: true,
        params: { showInherited: false },
      });
      return data;
    },
    select: (data): IamRole[] => {
      const normalizedRoles = data?.roles.map((role: IamRole) => normalizeIamRole(role));
      return (
        normalizedRoles?.filter((role: IamRole) => role.roleType === RoleType.USER_CLIENT_ROLE) ||
        []
      );
    },
    enabled: isManagerApp,
  });
