import { defineRecipe } from '@chakra-ui/react';

export const typographyMixins = {
  'Header.H1': {
    fontSize: '40px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '48px',
  },
  'Header.H2': {
    fontSize: '32px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '40px',
  },
  'Header.H3': {
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '34px',
  },
  Subheader: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '28px',
  },
  MainBody: {
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: '22px',
  },
  SmallBody: {
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  ExtraSmallBody: {
    fontSize: '12px',
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: '18px',
  },
  Link: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationSkipInk: 'none',
    textDecorationThickness: 'auto',
    textUnderlineOffset: 'auto',
    textUnderlinePosition: 'from-font',
  },
};

export const typographyRecipe = defineRecipe({
  variants: {
    variant: typographyMixins,
  },
});
