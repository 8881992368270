import {
  EntInvoicePaymentDetails,
  EntInvoicePaymentsDetails,
  InvoiceLineItemType,
} from '@m/types/api';
import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';
import { DateTime } from 'luxon';

/**
 * Get default billing method for `enterpriseId`
 * Will return `null` if no billing method set.
 */
export const getPaymentMethod = createEffect(({ enterpriseId }: { enterpriseId: number }) =>
  apiFetch(`/admin/enterprise/${enterpriseId}/payment-methods/default`),
);

export const getAllPaymentMethods = createEffect(({ enterpriseId }: { enterpriseId: number }) =>
  apiFetch(`/admin/enterprise/${enterpriseId}/payment-methods`),
);

export const createNewACHPaymentMethod = createEffect(
  ({ enterpriseId }: { enterpriseId: number }) =>
    apiFetch(`/admin/enterprise/${enterpriseId}/payment-methods/stripe-ach`, {
      method: 'POST',
    }),
);

export const deletePaymentMethod = createEffect(
  ({ enterpriseId, paymentMethodId }: { enterpriseId: number; paymentMethodId: number }) =>
    apiFetch(`/admin/enterprise/${enterpriseId}/payment-method/${paymentMethodId}`, {
      method: 'DELETE',
    }),
);

// charge invoice
export const chargeInvoiceServiceInvoice = ({
  body,
}: {
  body: {
    invoice_id: string;
    source: {
      customer_default: {
        enterprise_id: string;
      };
    };
  };
}) =>
  apiFetch('/v2/finance-operations/invoices/charge', {
    method: 'POST',
    body,
  });

// Make invoice editable by creating a copy and incrementing the invoice number
export const makeInvoiceEditable = ({ invoiceId }: { invoiceId: string }) =>
  apiFetch(`/v2/finance-operations/invoices/${invoiceId}/replace`, {
    method: 'POST',
    body: {
      invoiceId,
    },
  });

// update invoice status to paid (different from other status)
export const updateInvoiceServiceInvoiceStatusToPaid = ({
  invoiceId,
  body,
}: {
  invoiceId: string;
  body: {
    invoice_id: string;
    note?: string;
  };
}) =>
  apiFetch(`/v2/finance-operations/invoices/${invoiceId}/mark-as-paid`, {
    method: 'POST',
    body,
  });

// update invoice status
export const updateInvoiceServiceInvoiceStatus = ({
  invoiceId,
  body,
}: {
  invoiceId: string;
  body: {
    invoice_id: string;
    status: string;
    note?: string;
  };
}) =>
  apiFetch(`/v2/finance-operations/invoices/${invoiceId}/status`, {
    method: 'PUT',
    body,
  });

// retreive an invoice by uuid
export const getInvoiceServiceInvoice = (uuid: string, includeAdditionalInfo?: boolean) =>
  apiFetch(
    `/v2/finance-operations/invoices/${uuid}${includeAdditionalInfo ? '?includeAdditionalInfo=true' : ''}`,
  );

export const getAllInvoiceServiceInvoice = ({
  entId,
  fromInvoiceDate,
  toInvoiceDate,
  invoiceStatus,
}: {
  entId: number;
  fromInvoiceDate?: DateTime;
  toInvoiceDate?: DateTime;
  invoiceStatus?: string;
}) =>
  apiFetch('/v2/finance-operations/invoices', {
    method: 'POST',
    body: {
      customer: {
        enterpriseId: entId,
      },
      fromInvoiceDate,
      toInvoiceDate,
      invoiceStatus,
    },
  });

export const getInvoicePayments = (uuid: string) =>
  apiFetch(`/v2/finance-operations/history/invoices/${uuid}`);

export const getInvoicePaymentStatus = (uuid: string) =>
  apiFetch<{ paymentDetails: EntInvoicePaymentDetails; paymentUuid: string }>(
    `/v2/finance-operations/payments/${uuid}`,
  );

export const getInvoicePaymentsStatus = (uuid: string) =>
  apiFetch<{ payments: EntInvoicePaymentsDetails[] }>(
    `/v2/finance-operations/payments?paymentIds=${uuid}`,
  );

export const calculateNewInvoice = (body: {
  line_items: Partial<InvoiceLineItemType>[];
  customer: {
    enterprise_id: number;
  };
}) =>
  apiFetch('/v2/finance-operations/invoices/calculate', {
    method: 'POST',
    body,
  });

export const upsertNewLineItems = (data: {
  invoice_id: string;
  line_items: InvoiceLineItemType[];
}) =>
  apiFetch(`/v2/finance-operations/invoices/${data.invoice_id}/upsert-line-items`, {
    method: 'POST',
    body: {
      invoice_id: data.invoice_id,
      line_items: data.line_items,
    },
  });

export const createInvoice = (data: {
  invoice_id: string;
  invoice_frequency: string;
  invoice_date: string;
  line_items: InvoiceLineItemType[];
  customer: {
    enterprise_id: number;
  };
}) =>
  apiFetch('/v2/finance-operations/invoices/lineItems', {
    method: 'POST',
    body: {
      invoice_id: data.invoice_id,
      line_items: data.line_items,
      invoice_frequency: data.invoice_frequency,
      invoice_date: data.invoice_date,
      customer: data.customer,
      includeAdditionalInfo: false,
    },
  });

export const getInvoicePreview = (enterpriseId: number) =>
  apiFetch('/v2/finance-operations/invoices/upcoming-charges', {
    method: 'GET',
    params: { enterpriseId },
  });
