import React, { lazy, Suspense } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';

const SiteSurveyForm = lazy(() => import('apps/admin/components/SiteSurveyForm'));
const SiteSurveysList = lazy(() => import('apps/admin/components/SiteSurveysList/SiteSurveysList'));

export const locationSurveyRoutesConfig = [
  {
    exact: true,
    path: '/location-survey',
    breadcrumb: '',
    component: Route,
    children: (
      <Suspense fallback={null}>
        <SiteSurveyForm />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/location-survey/my-surveys',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: <Redirect to="/surveys" />,
  },
  {
    exact: true,
    path: '/surveys',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <SiteSurveysList />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/location-survey/:surveyId',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <SiteSurveyForm isApprovalFlow />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/location-survey/:surveyId/edit',
    breadcrumb: '',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <SiteSurveyForm isEditing />
      </Suspense>
    ),
  },
];

const createRoutes = () => {
  // Direct routes for location-survey
  const newRoutes = locationSurveyRoutesConfig.map(
    ({ component: Component, breadcrumb, ...props }) =>
      Component && <Component key={props.path} {...props} />,
  );

  // Redirect routes for site-survey
  const legacyRoutes = [
    <Route
      exact
      path="/site-survey"
      key="/site-survey"
      render={() => <Redirect to="/location-survey" />}
    />,
    <Route
      exact
      path="/site-survey/my-surveys"
      key="/site-survey/my-surveys"
      render={() => <Redirect to="/location-survey/my-surveys" />}
    />,
    <Route
      exact
      path="/site-survey/:surveyId"
      key="/site-survey/:surveyId"
      render={(props) => <Redirect to={`/location-survey/${props.match.params.surveyId}`} />}
    />,
    <Route
      exact
      path="/site-survey/:surveyId/edit"
      key="/site-survey/:surveyId/edit"
      render={(props) => <Redirect to={`/location-survey/${props.match.params.surveyId}/edit`} />}
    />,
  ];

  return [...newRoutes, ...legacyRoutes];
};

export default function LocationSurveyRoutes() {
  return <Switch>{createRoutes()}</Switch>;
}
